.submit-button {
    width: 100%;
    padding: $login-input-field-padding;
    background-color: $secondary-color;
    font-size: $button-caption;
    color: $text-color-button;
    border-radius: $login-border-radius;
    cursor: pointer;
    border: 1px solid $secondary-color;
}

.cancel-button {
    padding: $login-input-field-padding;
    background-color: white;
    font-size: $button-caption;
    color: $text-color;
    border: 1px solid $text-color;
    border-radius: $login-border-radius;
    cursor: pointer;
}

.sc-btn {
    all: unset;

    &__link {
        color: $link-color;
    }

    &__red {
        color: red;
    }
}

// Verify GST Buttons
.verify-gst__send-otp-class {
    display: flex;
    width: 100%;
    margin-top: 20px;
    align-items: center
}

.verify-gst__send-otp-class-without-flex {
    @extend .verify-gst__send-otp-class;
    display: block;
}

.verify-gst__submit-button {
    @extend .submit-button;
    width: $verify-gst-button-width;
    float: right;
}

.verify-gst__submit-button-disabled {
    opacity: 50%;
    cursor: auto;
}

.timer-button {
    width: 100%;
    padding: $login-input-field-padding;
    background-color: transparent;
    font-size: $button-caption;
    border-radius: $login-border-radius;
    color: black; 
    display: flex; 
    justify-content: center; 
    border: 1px solid $border-color;
}