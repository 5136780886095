.widget-card{
    border: 0.5px solid $border-color;
    @apply tw-py-3 tw-px-2 tw-rounded-xl tw-h-64;
    &__container{
        @apply tw-grid tw-gap-4 tw-py-6;
        grid-template-columns: repeat(3, 1fr);
    }
    &__heading{
        color: $primary-color;
        @apply tw-font-semibold tw-text-xl;
    }
    &__sub-heading{
        @extend .primary-text-color;
    }
}

.bar-graph-legend {
    border: 0.5px solid $border-color;
    @apply tw-text-center tw-p-3 tw-w-1/2;
    &__container{
        padding-left: 1rem;
        padding-right: 3px;
        @apply tw-flex;
    }
    &:last-child{
        @apply tw-border-l-0;
    }
}