.invoice-header__actions {
    @apply tw-flex tw-items-center tw-gap-5;

    // tw-pr-2
    &-item {
        @apply tw-flex;
    }
}

.invoice-header {
    @apply tw-flex tw-justify-between tw-items-center tw-py-4 tw-sticky tw-top-0 tw-mr-2;
}

.invoice-action {
    padding: 5px 7px !important;
    background: #d1d1d173 !important;
    border-radius: 4px !important;
    cursor: pointer;
}

.invoice-action__icons {
    width: 19px;
    height: 22px;
}

.highlight-yellow {
    background-color: #fde399;
    padding: 4px 10px 4px 5px;
}

.mat-sort-header-arrow {
    display: none !important;
}

.invoice-upload-modal__header {
    padding-top: 12px;
    padding-bottom: 12px;
    background: $primary-color;
    color: white;

    h4 {
        margin-bottom: 2px;
    }

    button {
        font-size: 30px;
    }
}

.verify-gst-popup-container {
    display: flex;
}

.highlight-green {
    background-color: rgb(79 204 145 / 81%);
    padding: 4px 10px 4px 5px;
}