.verify-gst__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 30px 20px 30px;
}

.verify-gst__set-reset {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.sidenav-container {

    .mat-drawer.mat-drawer-side {
        background: $icon-background;
        box-shadow: 8px 0 5px -2px #b4abab;
        z-index: 2;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        background: inherit;
    }

    .nav-pills .nav-link {
        color: $text-color;
        padding: 13px 10px;
    }

    mat-sidenav-content {
        background-color: #fff !important;
        // width: 100%;
    }

}

.setting-header {
    background-color: #fff !important;
    height: 44px;
    position: sticky;
    top: 0;
}

.disabled-sideNav {
    opacity: 0.5 !important;
    pointer-events: none !important;
}

.settings-sidenav-container .nav-pills .nav-link.active {
    border-left: 5px solid $secondary-color;
    border-radius: 0px;
    color: $text-color
}

.setting-outer-container {
    height: 100vh;
    overflow: auto;
}

.settings-sidenav {
    margin-top: 0px;
    margin-left: 30px;
}

.history-date {
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 30px;
}

.date-error-msg {
    padding-top: 5px;
    padding-left: 10px;
    color: red;
}

.history-date-field-div {
    padding: 10px;
}

.history-fields-div {
    display: flex;
}