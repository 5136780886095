// Login Padding and radius fields
$login-input-field-padding: 0.75em;
$login-border-radius: 5px;

// Fonts
$placeholder-text: 16px;
$page-heading: 24px;
$content-text: 12px;
$button-caption: 14px;
$text-link: 14px;
$error-text: 12px;
$input-label:12px;
$breadcrumb-title-font: 14px;


// Button width
$verify-gst-button-width: 120px;