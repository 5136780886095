.sc-table-container {
    overflow: auto;
    height: calc(100vh - 284px) !important;
}

.sc-table-container-max-height {
    overflow: auto;
    max-height: calc(100vh - 284px) !important;
}

// .sc-tabe-height {
//     max-height: 61vh;
// }

// .sc-table {
//     white-space: nowrap !important;
// }

.sc-table-header__row {
    background-color: $primary-color !important;
    color: white !important;
    height: unset !important;
    font-weight: 700 !important;
}

.sc-table-data__row {
    height: unset !important;
}

.sc-table__header {
    padding: 4px 16px !important;
}

.sc-table__data {
    padding: 10px 16px !important;
}

.sc-table-container .mat-mdc-table thead {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1;
}

.sc-table-container th.mat-mdc-header-cell.mdc-data-table__header-cell {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
}

.sc-table-container {
    .mat-mdc-paginator {
        position: sticky !important;
        bottom: 0 !important;
        right: 0 !important;
        left: 0 !important;


    }
}