@import "node_modules/@fortawesome/fontawesome-free/css/all.min.css";

.sc-icon-container{
    @extend .flex-center;
    @apply tw-p-2;
    @apply tw-rounded-xl;
    background-color: $icon-background;
}

.sc-icon {
    stroke: $secondary-color !important;
    height: 24px;
    width: 24px;
}

.sc-wide-icon-container {
    height: 46px;
    width: 43px;
    @extend .sc-icon-container;
    @apply tw-p-3;
    img{
        height: 22px;
        width: 19px;
    }
}