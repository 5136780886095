.filter-modal__container {
    position: fixed !important;
    right: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    height: 100%;
    width: 17% !important;
}

.filter-modal__content {
    height: 100%;
    overflow: auto;
}

.filter-body {
    font-size: 16px;
}

.filter-body__filed {
    margin: 1em 0 1em 0;
}