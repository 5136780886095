.logo-container {
    @apply tw-flex tw-items-center tw-gap-2 tw-pb-6;
}

.logo {
    @extend .primary-text-color;
    @apply tw-text-lg;
}

.sidenav-item-container {
    @apply tw-flex tw-items-center tw-gap-3 tw-py-2 tw-px-6;
}

.sidenav-item-container--active {
    @apply tw-rounded-xl;
    background-color: $primary-color;

    .sc-icon-container {
        background-color: transparent;
    }

    .heading-text {
        color: white;
    }

    .sc-icon {
        stroke: white !important;
    }
}

.app-content-container {
    @apply tw-my-6 tw-px-5 tw-mt-0 tw-relative tw-z-10 tw-h-full tw-ml-4;
    background: #fff !important;
}