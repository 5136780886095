* {
    margin: 0;
    font-family: "Nunito" !important;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Nunito" !important;
}

/* This will style all scrollbars within your page */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    border-radius: 6px;
    /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
    background: #074074;
    /* color of the scroll thumb */
    border-radius: 6px;
    /* roundness of the scroll thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* color of the scroll thumb on hover */
}

.disabled-sideNav {
    opacity: 0.5;
    pointer-events: none;
}

.mat-drawer-content {
    z-index: 2 !important;
    // overflow: hidden !important;
}

.toggle-heading {
    padding: 7px;
    border-radius: 2px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-bottom: 0px !important;
    display: flex;
    flex-direction: row;
    border: 1px solid #ccc;
}

.toggle-body {
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 14px 11px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: 0px;
}

.toggle-heading__text {
    flex: 1;
    color: rgba(39, 39, 39, 0.8);
    font-weight: 600;
}

.toggle-heading__icon {
    flex: 0 1;
    font-size: 28px;
    font-weight: bolder;
    color: #4fcc91;
}

.require-astrik {
    color: red;
}

.error-text {
    color: red;
    font-size: 13px;
}

.error-body {
    margin-top: 10px
}

.files-row span {
    flex: 15 1;
}

.files-row img {
    flex: 1 0
}

.files-row {
    margin-top: 10px;
}

.secondary-color {
    background-color: $secondary-color !important;
    border: 1px solid $secondary-color !important;
}

.mat-datepicker-toggle {
    position: absolute;
    right: 10px;
}

.date-field {
    align-items: center;
    position: relative;
}

.sync-data {
    background-color: $secondary-color !important;

    span {
        margin-left: 5px
    }
}

app-header {
    position: sticky !important;
    margin-top: 0 !important;
    z-index: 1;
}

.mat-mdc-select-disabled {
    background: #dddddd8f !important;
}

// .mandatory-sidenav-class {
//     height: 250vh;
// }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.modal__cancle {
    border-color: #000 !important;
    padding: 8px 30px !important;
}

.modal__upload {
    background-color: #4fcc91 !important;
    border-color: #4fcc91 !important;
    padding: 8px 30px !important;
    color: #fff !important;
}

.filter-modal__title {
    margin-bottom: -11px !important;
}

.modal-background {
    background-color: #0000008c;
}

.sorting-note {
    margin-left: 12px;
    font-size: 13px;
    display: flex;
}

.receivable-heading {
    display: flex;
    font-weight: 600 !important;
}

.info-image {
    vertical-align: baseline;
    flex: 0 1;
    margin-right: 5px;
}

.sortin-note__text {
    flex: 1 0;
    font-weight: 400;
}