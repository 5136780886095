
.primary-text-color{
    color: $text-color;
}

.heading-text{
    color: $text-color;
    @apply tw-font-semibold;
}

.faint-primary-text{
    color: $text-color;
    @apply tw-opacity-60
}

.faint-secondary-text {
    color: $text-secondary-color;
}

.faint-bold-secondary-text{
    @extend .faint-bold-secondary-text;
    @apply tw-font-semibold;
}