.flex-column {
    @apply tw-flex;
    flex-direction: column;
    // &-h-c{
    //     justify-content: center;
    // }
    // &-v-c{
    // }
}
.flex-h-c {
    justify-content: center; // Horizontally Center
}
.flex-v-c {
    align-content: center; // Vertically Center
    align-items: center;
}
.flex-row{
    @apply tw-flex;
    flex-direction: row;
}
.flex-center{
    @apply tw-flex tw-justify-center tw-items-center;
}