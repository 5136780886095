.stats-card {
    border: 0.5px solid $border-color;
    @apply tw-p-2 tw-rounded-xl tw-h-full;

    &__heading {
        color: $text-secondary-color;
        @apply tw-font-semibold;
    }

    &__values-container {
        @apply tw-flex tw-items-center tw-gap-2;
    }

    &__main-value {
        @extend .primary-text-color;
        @apply tw-text-xl;
    }

    &__sub-value {
        color: $secondary-color;
        @apply tw-font-semibold tw-text-sm;
    }

    &__container {
        @apply tw-grid tw-gap-x-5 tw-gap-y-5;

        @media screen and (min-width: 768px) {
            grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
        }

        @media screen and (min-width: 992px) {
            grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
        }

        @media screen and (min-width: 1200px) {
            grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
        }

        @media screen and (min-width: 1500px) {
            grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
        }

        @media screen and (min-width: 1800px) {
            grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
        }
    }
}