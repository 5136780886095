
.login__container {
    flex: 1.5;
    // @extend .flex-class;
    @extend .flex-column, .flex-h-c, .flex-v-c;
}
.login__form-class {
    width: 100%;
}
.login__content {    
    @extend .flex-column, .flex-h-c, .flex-v-c;
    width: 350px;
}
.login__header-text {
    font-size: $page-heading;
    padding: 5px;
}
.login__input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: $content-text;
}
.login__submit-button-class {
    @extend .flex-column, .flex-h-c, .flex-v-c;
    margin-top: 20px;
    width: 100%;
}
.login__anchor-tag{
    text-decoration: underline;
    color: $primary-color;
    font-weight: bold;
}
.login__text-center{
    text-align: center;
}
.login__password {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.login__password-error-message {
    height: 10px;
    color: red;
    font-size: $error-text;
}

.sent-otp-headline {
    font-size: 15px;
    padding-bottom: 5px;

    .otp-color {
        color: $primary-color;
        font-weight: bold;
    }

    &.enter-otp-text {
        color: $secondary-color;
        font-weight: bold;
    }
}

.otp-expired-span {
    color: red;
    margin-bottom: 20px
}
  