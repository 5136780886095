.invoice-upload-modal__header {
    padding-top: 12px;
    padding-bottom: 0px;
    background: #074074;
    color: white;

    h4 {
        margin-bottom: 2px;
    }

    button {
        font-size: 30px;
    }
}

.upload-container {
    border: 2px dashed #ccc;
    padding: 20px
}

.invoice-upload-modal__body {
    padding: 40px;
}

.invoice-upload-modal__footer {
    display: block;
}

.invoice-upload-modal__upload {
    background-color: #4fcc91 !important;
    border-color: #4fcc91 !important;
    padding: 8px 30px !important;
    color: #fff !important;
}

.invoice-upload-modal__cancle {
    border-color: #000;
    padding: 8px 30px;
}

.upload-container__remove {
    @apply tw-flex tw-items-center tw-gap-5 tw-pr-2;

    &-item {
        @apply tw-flex;
    }
}

.progress-container__header {
    @apply tw-flex tw-justify-between tw-items-center tw-py-4;
}

.progress-container__filename {
    text-align: left;
}

.progress-container {
    margin-top: 20px;
    overflow: auto;
    max-height: 300px;
}

.progress-container__files {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding-top: 0px;
    margin: 5px;
}

.progress-container__progress {
    height: 12px;
}

.progress-container__bar {
    background: #4fcc91;
}

.upload-container__upload-image {
    cursor: pointer
}

.download-template-class {
    margin-top: 10px;
    margin-right: 10px;
    float: right;
    display: flex;
    flex-direction: row-reverse;
}

a {
    text-decoration: none !important;
    color: inherit !important;
}

.file-label {
    @apply tw-ml-28
}

.gstin-verify-modal__close {
    background-color: #4fcc91;
}