.table-toolbar__status-indicators {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.table-toolbar__status-indicator {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.table-toolbar__status-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 3px;
}

.table-toolbar__status-title {
    display: inline;
}