
.mdc-switch:enabled .mdc-switch__track::after{
    background-color: $secondary-color !important;
}

.mdc-switch:enabled:hover .mdc-switch__track::after{
    background-color: $secondary-color !important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon{
    fill: transparent !important;
}

.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon{
    fill: transparent !important;
}

.mdc-switch .mdc-switch__handle{
    border-radius: 1rem !important;
    height: 11px !important;
    width: 11px !important;
}

.mdc-switch__icons{
    background-color: white !important;
    border-radius: 1rem;
}

.mdc-switch:enabled:hover .mdc-switch__track::before {
    background: $border-color !important;
}
.mdc-switch:enabled .mdc-switch__track::before {
    background: $border-color !important;
}

.mdc-switch .mdc-switch__handle-track {
    width: calc(100% - 15px) !important;
}

.mdc-switch__handle-track{
    left: 2px !important;
}