// Primary and Secondary Colors
$primary-color: #074074;
$secondary-color: #4fcc91;

$icon-background: #d1d1d1ad;

// 
$input-field-stroke-color: #e2e8f0;
$placeholder-text-color: rgba(39, 39, 39, 0.8);
$text-color: rgba(39, 39, 39, 0.8);
$text-secondary-color: #A0AEC0;
$link-color: #0085ff;
$profile-background: #f3f8fe;

$text-color-button: white;
$border-color: rgba(132, 129, 129, 0.5);
$widget-heading: #9faec0;