.header-container {
    @apply tw-flex tw-justify-between tw-items-center tw-py-5 tw-sticky tw-top-0;
    background-color: #fff;
    z-index: 1;

    &__settings {
        @apply tw-flex tw-items-center tw-gap-5 tw-pr-2;

        &-item {
            @apply tw-flex;
        }
    }

    &__icon {
        font-size: 1.2rem;
    }

    &__profile {
        @apply tw-h-12 tw-w-12 tw-rounded-full tw-object-fill tw-cursor-pointer;
        border: 2px solid $icon-background;

        &--not-found {
            background-color: $icon-background;
            @extend .header-container__profile, .flex-center;
        }
    }

    &__modal-profile {
        @extend .header-container__profile;
        @apply tw-h-16 tw-w-16;
        border: 4px solid white;

        &--not-found {
            background-color: $icon-background;
            @extend .header-container__modal-profile, .flex-center;
        }
    }
}

.search-input-container {
    @apply tw-flex tw-items-center;

    .search-icon {
        @apply tw-h-4 tw-w-4 tw-absolute tw-ml-2;
    }

    .search-input {
        padding: 0.5rem 1rem;
        border: 1px solid $input-field-stroke-color;
        @apply tw-rounded-xl tw-pl-8;

        &:focus {
            @apply tw-outline-none;
        }
    }
}

.profile-background {
    background-color: $profile-background;
}

.all-companies {
    color: green;

    &__container {
        max-height: 250px;
        @apply tw-overflow-y-scroll;
    }
}