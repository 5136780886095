.login__input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: $content-text;
    margin: 1em 0 1em 0;
}

.login__input-field:focus {
    outline: none;
}

.login__input-field:focus {
    border: 1px solid green !important;
}

.login__input-field {
    width: 100%;
    padding: $login-input-field-padding;
    border: 1px solid $border-color;
    border-radius: $login-border-radius;
}

.login__input-label {
    font-size: $input-label;
}

.login__required-field::after {
    content: ' *';
    color: red;
}

.login__password-eye {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.login__input-field-password {
    @extend .login__input-field;
    flex: 1;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.login__input-field-password-eye {
    padding-right: 10px;
}

.login__password-div {
    width: 100%;
}

.login__password-eye-image {
    float: right;
    border-right: 1px solid $input-field-stroke-color;
    border-top: 1px solid $input-field-stroke-color;
    border-bottom: 1px solid $input-field-stroke-color;
    border-top-right-radius: $login-border-radius;
    border-bottom-right-radius: $login-border-radius;
    height: 100%;
    width: 30px;
    padding: 5px 10px 5px 5px;
}

// VERIFY GST INPUT FIELDS (Settings)
.verify-gst__input-group {
    display: flex;
    margin-top: 20px;
}

.verify-gst__input-label {
    @extend .login__required-field;
    width: 40%;
    display: flex;
    align-items: center;
}

.verify-gst__input-label-without-required {
    width: 40%;
    display: flex;
    align-items: center;
}

.verify-gst-header {
    padding: 30px 0px 0px 30px;
}