.mat-mdc-tab .mdc-tab__text-label {
    color: $text-color;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    color: $secondary-color !important;
}


.mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: $secondary-color !important;
    border-top-width: 3px !important;
}

.mat-mdc-tab.mdc-tab {
    height: auto !important;
    padding: 0.75rem 1rem !important;
}

.mat-mdc-tab-list {
    background-color: $icon-background !important;
    padding: 0px 0.5rem;
}

.mat-mdc-tab-body-wrapper {
    padding: 1rem 0px !important;
}