@font-face {
    font-family: "Nunito";
    src: url('./../../fonts/Nunito-Light.ttf') format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Nunito";
    src: url('./../../fonts/Nunito-Regular.ttf') format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Nunito";
    src: url('./../../fonts/Nunito-Medium.ttf') format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Nunito";
    src: url('./../../fonts/Nunito-SemiBold.ttf') format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "Nunito";
    src: url('./../../fonts/Nunito-Bold.ttf') format("truetype");
    font-weight: 700;
}